import React from 'react';
import { Login } from '@phpcreation/frontend-components-crud-react';
import Head from 'next/head';

type Props = {
  cycle: string | undefined;
  appEnv: string | undefined;
};

const IndexLogin = (props: Props) => {
  return (
    <Login
      sampleResource="tenants"
      fqcn_bui={{ Bundle: 'securityBundle', Unit: 'login', Interface: 'login' }}
      devUsername="admin"
      devPassword="phpc"
      cycle={props.cycle}
    />
  );
};

export function getStaticProps() {
  const cycle = process.env.npm_lifecycle_event;
  let appEnv = process.env.APP_ENV;

  if (!appEnv) appEnv = 'prod';

  return {
    props: {
      appEnv,
      cycle,
    },
  };
}

export default IndexLogin;
